import React from "react"
import SEO from "components/seo";
import ContactForm from "../components/Contact";

export default function Home({location}) {
  return (
    <>
      <SEO title="Accueil" />
      <div>
        <p>Contactez-nous pour la réalisation de votre site internet : </p>
        <ContactForm location={location}/>
      </div>
    </>
  );
}
