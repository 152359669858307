import React from "react"
import { Form, Col, InputGroup, Button } from 'react-bootstrap'
import axios from "axios"
import * as qs from "query-string"

export const ContactForm = ({location, success, error}) => {

  const fieldsDefault = {
    name: '',
    email: '',
    phone: '',
    message: '',
    ...qs.parse(location.search),
  }

  const [validated, setValidated] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [fields, setFields ] = React.useState(fieldsDefault);
  const [ feedback, setFeedback] = React.useState(null);

  const handleChangeField = (name) => (evt) => {
    fields[name] = evt.target.value || '';
    setFields({...fields});
  };

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    const formData = {...fields, email, "form-name": 'Contact'};
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }else{
      //Send value via netlify
      const axiosOptions = {
        url: location.pathname,
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: qs.stringify(formData),
      }
      axios(axiosOptions)
        .then(response => {
          setFeedback({status: 'success', message: success});
          form.reset()
        })
        .catch(err =>
          setFeedback({status: 'error', message: error}));
    }
    setValidated(true);
  };
  return (
    <Form name="Contact" noValidate netlify-honeypot="bot-field" action={location.pathname} validated={validated} onSubmit={handleSubmit} data-netlify="true">
      <input type="hidden" name="bot-field" />
          <Form.Row>
            <Form.Group as={Col} md="12" controlId="validationCustom01">
              <Form.Label>Nom</Form.Label>
              <Form.Control
                required
                type="text"
                name="name"
                value={fields.name}
                onChange={handleChangeField('name')}
                placeholder="Nom"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="12" controlId="validationCustom02">
              <Form.Label>Téléphone</Form.Label>
              <Form.Control
                required
                type="phone"
                name="phone"
                value={fields.phone}
                onChange={handleChangeField('phone')}
                placeholder="0600000000"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="12" controlId="validationCustom03">
              <Form.Label>Email</Form.Label>
              <InputGroup>
                <Form.Control
                  type="email"
                  name="email"
                  value={email}
                  onChange={(evt) => setEmail(evt.target.value)}
                  placeholder="Email"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Entrez votre addresse email.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="12" controlId="exampleForm.ControlTextarea1">
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" rows="3" name="message"
              value={fields.message}
              onChange={handleChangeField('message')}
              />
            </Form.Group>
          </Form.Row>
          <Button type="submit">Envoyer</Button>
        </Form>
  );
}

export default ContactForm;