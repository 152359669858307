import React from "react"
import SEO from "components/seo";

export default function Thanks() {
  return (
    <>
      <SEO title="Merci" />
      <div>
        <p>Merci pour votre message, nous allons vous recontacter bientôt.</p>
      </div>
    </>
  );
}
