import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'
import "./Nav.scss"

const Nav = ({siteTitle}) => {


  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `);
  return (
    <div className="Nav">
      <Img style={{
                  width:"250px"}} fluid={data.placeholderImage.childImageSharp.fluid} alt="4LW logo"  />
    </div>
  );
}

export default Nav;